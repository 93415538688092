import React, { Component } from 'react';
/* components */
import Contents from './component/Contents.jsx';
import ControlList from './component/ControlList.jsx';
/* css */
import './App.css';
/* librerias */
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAddressBook, faHome, faListUl, faShareAlt } from '@fortawesome/free-solid-svg-icons';
/* svg */
import koulutus from './svg/logo-koulutus.svg';
import facebook from './svg/facebook.svg';
import instagram from './svg/instagram.svg';
/* import twitter from './svg/twitter.svg'; */
import linkedin from './svg/linkedin.svg';
/* import youtube from './svg/youtube.svg'; */
import whatsapp from './svg/whatsapp.svg';
import email from './svg/email.svg';
import phone from './svg/phone.svg';

class App extends Component {
  
  constructor(props){
    super(props)
    this.state={      
      contentsVw:true,
      listVw:false,
      contactsVw:false,
      shareVw:false
    }
  }

  contentsVw(){
    this.setState({
      contentsVw:true,
      listVw:false,
      contactsVw:false,
      shareVw:false
    });    
  }

  listVw(){
    this.setState({
      contentsVw:false,
      listVw:true,
      contactsVw:false,
      shareVw:false
    });    
  }

  componentDidMount(){
    var elems = document.querySelectorAll('.fixed-action-btn');
    var instances = M.FloatingActionButton;
    instances.init(elems, {
        direction: 'top',
        hoverEnabled: false
      });
  }

  render() {
    return (
      <div className="App">
        {/* header */}
        <div className="App-header">
            <img src={koulutus}  width="150px" className="App-logo" alt="logo"/>
        </div>
        {/* section */} 
        <div className="App-cont">
          <div className="App-mg">

            {
              this.state.contentsVw?
              <Contents/>
              :null
            }

            {
              this.state.listVw?
              <ControlList/>
              :null
            }

          </div>
        </div>
        {/* footer */}
        <footer className="footer">
          <div className="footer__nav">
            {/* home */}
            <div className="App-btn" >
              <span className="App-lnk" style={{cursor:'pointer'}} onClick={(e) => this.contentsVw()}>
                <span className="">
                  <FontAwesomeIcon icon={faHome}/>
                </span>
              </span>
            </div>
            {/* list-brochure */}
            <div className="App-btn">
              <span className="App-lnk" style={{cursor:'pointer'}} onClick={(e) => this.listVw()}>
                <span className="">
                  <FontAwesomeIcon icon={faListUl}/>
                </span>
              </span>
            </div>
            {/* to share */}
            <div className="App-btn">
              <span className="App-lnk" style={{cursor:'pointer'}}>
                <div className="fixed-action-btn" style={{position:'relative', right:0, bottom:0, paddingTop:0}}>
                  <a className="" href="/#">
                    <span className="lnk" style={{color:'white'}}>
                      <FontAwesomeIcon icon={faShareAlt}/>
                    </span>
                  </a>
                  <ul>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#3b5998', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://www.facebook.com/andresfelipe.valenciaferro/" target="_blank" rel="noreferrer">
                        <img src={facebook} alt="facebook" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#125688', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://www.instagram.com" target="_blank" rel="noreferrer">
                        <img src={instagram} alt="instagram" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#007bb5', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://www.linkedin.com/in/andres-felipe-valencia-ferro-12b0063a" target="_blank" rel="noreferrer">
                        <img src={linkedin} alt="linkedin" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </div>
            {/* contacts */}
            <div className="App-btn">
              <span className="App-lnk" style={{cursor:'pointer'}}>
                <div className="fixed-action-btn" style={{position:'relative', right:0, bottom:0, paddingTop:0}}>
                  <a className="" href="/#">
                    <span className="lnk" style={{color:'white'}}>
                      <FontAwesomeIcon icon={faAddressBook}/>
                    </span>
                  </a>
                  <ul>
                    <li>
                      <a className="btn-floating" style={{backgroundColor:'#4dc247', display:'flex', justifyContent:'center', alignItems:'center'}} href="https://api.whatsapp.com/send?phone=573135906515">
                        <img src={whatsapp} alt="whatsapp" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating red" style={{display:'flex', justifyContent:'center', alignItems:'center'}} href="mailto:ceo@koulutus.com.co​">
                        <img src={email} alt="email" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating blue" style={{display:'flex', justifyContent:'center', alignItems:'center'}} href="tel:+573135906515​">
                        <img src={phone} alt="phone" style={{width:'30px', height:'30px', cursor:'pointer'}}></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </div>
          </div>
        </footer>        
      </div>   
    );
  }
}

export default App;