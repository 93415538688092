import React, { Component } from "react";
import "../styles/flipbookDt.css";
import "../styles/styleVw.css";
import M from 'materialize-css';

const userMac= navigator.platform.toUpperCase().indexOf('MAC') >=0;
const userWin= navigator.platform.toUpperCase().indexOf('WIN') >=0;


class DisplayDt  extends Component{

    componentDidMount(){
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox;
        instances.init(elems);
      }

    flipPageNext(elemt, index, view, elemenVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        
        page.classList.add('flipEfectNext');

        if (!elemenVideo){
            
        }else{
            let vpause= document.querySelector('video');
            vpause.pause();
        }
        
        if (view === 1){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'))), 1000);
        }else{
            setTimeout(() => (page.style.zIndex = index), 1000);
        }
        
        if(userMac || userWin){
            let contened = document.getElementsByClassName('flip-container')[0];
            contened.style.transform = 'translateX(50%)';
        }

        /* let tamaño = window.screen.width;
        let tamaño2 = window.screen.height;
        alert(tamaño);
        alert(tamaño2); */
    }

    flipPageBack(elemt, index, view, elemenVideo){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        let evaUser = (userMac || userWin) && index === 10;

        page.classList.remove('flipEfectNext');
       
        if (view === 0){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 1000);
        }else{
            setTimeout(() => (page.style.zIndex = index), 1000);
        }
        
        if(evaUser){
            let contened = document.getElementsByClassName('flip-container')[0];
            contened.style.transform = 'translateX(0%)';
        }
    }
    
    render(){
        return(
            <div id="page-flip">
                <div className="flip-container">
                    {/* paper 1 */}
                    <div id="page1" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page1">
                                <div className="goBack"></div>
                                <div className="front__content-text">
                                    <div style={{height:'80%', display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                                        <h5 style={{color:'#00abec'}}>Color y vida a tus ideas</h5>
                                        <h4 style={{marginBottom:'5px', color:'#42369a'}}>IMPRESIONES</h4>
                                        <h6 style={{paddingBottom:'30px', color:'#42369a'}}>DE ALTA RESOLUCIÓN</h6>
                                    </div>
                                    <div style={{height:'20%', display:'flex', alignItems:'center'}}>
                                        <p style={{color:'#6d6e72'}}>
                                            Gamma Comunicación Gráfica S.A.S  cuenta  con amplia experiencia produciendo piezas publicitarias con alta resolución 
                                            en materiales como: Cartón, Acrílico, Banner, MDF, Foamboard, Vinilos, Lamina Metálica, Floorgraphics, Duratrans, 
                                            entre otros. 
                                        </p>
                                    </div>
                                </div>
                                <div className="next" onClick={(e) => this.flipPageNext('page1', 1)}></div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page2">
                                <div className="goBack" onClick={(e) => this.flipPageBack('page1', 10)}></div>
                                <div className="front__content-text">
                                    <div style={{height:'20%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <h6 style={{color:'#42369a'}}>Estimados Señores</h6>
                                    </div>
                                    <div style={{height:'90%', width:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                        <p style={{paddingBottom:'5px', color:'#6d6e72'}}>
                                            Por medio del presente, aprovechamos para expresarles un cordial saludo y a la vez presentarles a 
                                            GAMMA COMUNICACIÓN GRÁFICA S.A.S, empresa colombiana formada por profesionales en el área publicitaria.  
                                        </p>
                                        <p style={{paddingBottom:'5px', color:'#6d6e72'}}>
                                            Gamma Comunicación Gráfica S.A.S  cuenta  con amplia experiencia produciendo piezas publicitarias con alta resolución 
                                            en materiales como: Cartón, Acrílico, Banner, MDF, Foamboard, Vinilos, Lamina Metálica, Floorgraphics, Duratrans, 
                                            entre otros. 
                                        </p>
                                        <p style={{paddingBottom:'5px', color:'#6d6e72'}}>
                                            En este mismo orden, ponemos a su disposición toda nuestra infraestructura y gestión creativa enfocada al ámbito 
                                            publicitario. 
                                        </p>
                                        <p style={{paddingBottom:'5px', color:'#6d6e72'}}>
                                            Contamos con una amplia experiencia en campañas publicitarias, empleamos artículos publicitarios de gran Impacto visual, 
                                            creatividad al diseñar, manteniendo la calidad que nos caracteriza.
                                        </p>
                                        <p style={{paddingBottom:'5px', color:'#6d6e72'}}>
                                            Esperamos nos pueda tomar en cuenta para sus futuros proyectos de marketing, quedamos a la espera de su positiva respuesta.  
                                        </p>
                                        <p style={{color:'#6d6e72'}}>
                                            Cordial saludo  
                                        </p>
                                        <p style={{color:'#6d6e72'}}>
                                            Elena Salazar  
                                        </p>
                                        <p style={{color:'#6d6e72'}}>
                                            Gerente 
                                        </p>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 2 */}
                    <div id="page2" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page3">
                                <div className="goBack"></div>
                                <div className="front__content-text">
                                    <div style={{width:'100%', height:'25%', display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                                        <h4 style={{margin:0, paddingBottom:'5px', color:'#42369a'}}>¿Quiénes</h4>
                                        <h6 style={{color:'#42369a', paddingBottom:'5px'}}>Somos?</h6>    
                                    </div>
                                    <div style={{width:'100%', height:'75%'}}>
                                        <p style={{color:'#6d6e72', width:'85%', textAlign:'left', paddingBottom:'5px'}}>
                                            Gamma Comunicación Gráfica S.A.S  es una empresa de servicios comprometida con el medio  ambiente, enfocada en el área de comunicación y marketing.
                                        </p>
                                        <p style={{color:'#6d6e72', width:'70%', textAlign:'left'}}>
                                            Nuestra empresa es especialista en desarrollo de campañas publicitarias, contamos con una amplia experiencia produciendo piezas
                                        </p>
                                        <p style={{paddingBottom:'10px', color:'#6d6e72', width:'50%', textAlign:'left'}}>
                                            publicitarias en materiales como: Cartón, Acrílico, Banner, MDF, Foamboard, Vinilos, Lamina Metálica, Floorgraphics, Duratrans, entre otros.
                                        </p>
                                    </div>
                                </div>
                                <div className="next" onClick={(e) => this.flipPageNext('page2', 2)}></div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page4">
                                <div className="goBack" onClick={(e) => this.flipPageBack('page2', 9)}></div>
                                <div className="front__content-text">
                                    <div style={{width:'100%', height:'20%', display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                                        <h4 style={{margin:0, paddingBottom:'5px', color:'#42369a'}}>Misión</h4>   
                                    </div>
                                    <div style={{width:'100%', height:'30%'}}>
                                        <p style={{color:'#6d6e72', width:'73%', textAlign:'left', paddingBottom:'5px'}}>
                                            Nuestra misión es satisfacer las necesidades de impresión de nuestros clientes a través del trabajo en equipo con nuestros colaboradores y 
                                            proveedores, contando con la mejor tecnología, creatividad, servicio y cumplimiento en todos nuestros productos y servicios. 
                                        </p>
                                    </div>
                                    <div style={{width:'100%', height:'20%', display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                        <h4 style={{margin:0, paddingBottom:'5px', color:'#42369a'}}>Visión</h4>   
                                    </div>
                                    <div style={{width:'100%', height:'30%', display:'flex', justifyContent:'flex-end'}}>
                                        <p style={{color:'#6d6e72', width:'72%', textAlign:'right', paddingBottom:'5px'}}>
                                            Ser la mejor empresa del país en el ámbito publicitario que otorgue servicios de impresión digital, de excelente calidad y con un tiempo de entrega óptimo; 
                                            además de una organización siempre comprometida con la sociedad y el medio ambiente, pero sobre todo con sus empleados, clientes y proveedores.
                                        </p>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 3 */}
                    <div id="page3" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page5">
                                <div className="goBack"></div>
                                <div className="front__content-text">
                                    <div style={{height:'30%'}}>
                                        <h4 style={{marginBottom:'10px', color:'#42369a'}}>IMPRESIÓN UV</h4>
                                        <h6 style={{paddingBottom:'10px', color:'#42369a'}}>Sobre Rígidos 1.5m x <span>&#8734;</span></h6>
                                    </div>
                                    <div  style={{height:'35%', width:'100%'}}>
                                        <video id = "video1" className="cVideo" src="./media/199.mp4" controls preload="none" poster="./img/14.png">
                                            <source src="./media/199.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <img className="materialboxed" width="80" height="120" src="./img/14.png" alt=""/>    
                                        <img className="materialboxed" width="80" height="120" src="./img/14.png" alt=""/>
                                        <img className="materialboxed" width="80" height="120" src="./img/14.png" alt=""/>
                                    </div>
                                </div>
                                <div className="next" onClick={(e) => this.flipPageNext('page3', 3, 1, 'video1')}></div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page6">
                                <div className="goBack" onClick={(e) => this.flipPageBack('page3', 8, 0, 'video1')}></div>
                                <div className="front__content-text">
                                    6
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 4 */}
                    <div id="page4" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page7">
                                <div className="goBack"></div>
                                <div className="front__content-text">
                                    7
                                </div>
                                <div className="next" onClick={(e) => this.flipPageNext('page4', 4)}></div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page8">
                                <div className="goBack" onClick={(e) => this.flipPageBack('page4', 7)}></div>
                                <div className="front__content-text">
                                    8
                                </div>
                                <div className="next">ss</div>
                            </div>
                        </div>
                    </div>
                    {/* paper 5 */}
                    <div id="page5" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page9">
                                <div className="goBack"></div>
                                <div className="front__content-text">
                                    9
                                </div>
                                <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page10">
                                <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div>
                                <div className="front__content-text">
                                    10
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                </div>              
            </div>      
        );
    }
}

export default DisplayDt;