import React, { Component } from "react";
/* css */
import "../styles/flipbookMv.css";
import "../styles/styleVw.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCaretRight, faExclamationCircle, faPhoneSquareAlt, faPlus, faVideo} from '@fortawesome/free-solid-svg-icons';
import koulutus from '../svg/logo-koulutus.svg';
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

class DisplayMv  extends Component{

    componentDidMount(){
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox;
        instances.init(elems);

        var elmModal = document.querySelectorAll('.modal');
        var instModal = M.Modal;
        instModal.init(elmModal);
      }

    flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo = document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');

        page.classList.add('movFlipEfectNext');
        
        if (!elemenVideo){
            /* null */
        }else{
            let vpause= document.querySelectorAll('video')[indexVideo];
            vpause.pause();
        }

        if (view === 1){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
        }else{
            setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                setTimeout(()=> (e.classList.add('eVisibility')), 800); 
            }
        });


        /* let tamaño = window.screen.width;
        let tamaño2 = window.screen.height;
        alert(tamaño);
        alert(tamaño2); */
 
    }

    flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');
        
        page.classList.remove('movFlipEfectNext');
        page.style.transform ='translateX(0%)';

        if (view === 0){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 500);
        }else{
            setTimeout(() => (page.style.zIndex = index), 500);
        }
        
        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                e.classList.remove('eVisibility'); 
            }
        });
    }

    render(){
        return(
            <div className="flip-container">
                {/* paper 1 */}
                <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content background-portada">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="front__portada">
                                    <img className="front__logo" style={{maxWidth:"100%", height:'auto', position:'absolute'}} src="./img/1.png" alt="logo"/>
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img className="front__logo circle" style={{maxWidth:"35%", height:'auto'}} src="./img/2.png" alt="logo"/>
                                                    <ul>
                                                        <li><span className="gbold">Andrés Felipe Valencia Ferro</span></li>
                                                        <li className="sizetext"><span className="bold grey-text">C.C: </span><span className="light"></span>94.544.023</li>
                                                        <li className="sizetext"><span className="bold grey-text">Fecha de Nacimiento: </span><span className="light">02-06-1985</span></li>
                                                        <li className="sizetext"><span className="bold grey-text">Estado Civil: </span><span className="light">Casado</span></li>
                                                        <li className="sizetext"><span className="bold grey-text">Dirección: </span><span className="light">Av. 2bis norte #25N–39 Cali, Colombia</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'15px', paddingTop:'15px'}}>PERFIL PROFESIONAL</div>
                                    <div className="container" style={{overflowY:'auto', height:'38%', width:'97%'}}>
                                        <p className="light grey-text">
                                            Licenciado en informática educativa y estudios de ingeniería de sistemas y telecomunicaciones, con experiencia en formación de educadores y educandos de grado primero a once, 
                                            experiencia en coordinación académica y desarrollo curricular. Soy una persona con capacidad creativa, espíritu cooperador y liderazgo, buena expresión oral, excelentes relaciones interpersonales, 
                                            rapidez para aprender, autónomo en la toma de decisiones, aptitud para el orden, altamente creativo e innovador, trabajo en equipo, actuó con ética, eficiencia y responsabilidad, Con afinidad en el campo lógico-matemático. 
                                            Además de capacidad para estructurar proyectos y generar una planificación con altos índices de asertividad, enfocados a procesos emprendedores. Además de conferencias de liderazgo y oratoria. 
                                        </p>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}>HABILIDADES</div>
                                    <div className="container" style={{height:'43%', width:'97%'}}>
                                        <ul className="collection" style={{opacity:'0.8', margin:0}}>
                                            <li className="bold collection-header white-text" style={{padding:'10px', backgroundColor:'rgb(240, 179, 35)'}}><h6>Informática</h6></li>
                                        </ul>
                                        <div style={{overflowY:'auto', height:'80%'}}>
                                            <ul className="collection" style={{opacity:'0.8', margin:0}}>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> Experto en las herramientas de Office.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> Visual Basic nivel básico.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> Wix, Blogger, Wordpress nivel intermedio.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> Edición de vídeo nivel básico-intermedio.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> Mantenimiento de redes nivel básico.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> Conocimientos técnicos de mantenimiento de hardware y software, nivel intermedio- avanzado.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> Suite de Adobe: Flash, Illustrator, Dreamweaver, Photoshop nivel básico.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> Manejo de la plataforma virtual Moodle, nivel Medio.</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>OTRAS HABILIDADES</div>
                                    <div className="container" style={{overflowY:'auto', height:'34%', width:'97%'}}>
                                        <p className="light grey-text">
                                            Implementación de articulación educativa con la universidad Autónoma de Occidente. Creación e implementación de la primera feria empresarial del colegio Santa María de Pance, 
                                            dirigida a fomentar el espíritu emprendedor y comercial de los estudiantes de grado once. La feria tuvo un alto impacto en la comunidad educativa y hoy ya se extendió a varios 
                                            colegios y universidades. Creación de presentaciones audiovisuales que pueden ser interactivas o no. 
                                        </p>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%', width:'100%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}>FORMACIÓN ACADÉMICA</div>
                                    <div className="container" style={{width:'97%'}}>
                                        <table className="highlight">
                                            <tbody>
                                              <tr>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>Junio 2015</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Licenciado en Informática Educativa</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal1">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                    <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./title/pregrado.pdf" target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>Agosto 1993 – Julio 2001</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Bachiller comercial</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal2">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                    <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./title/bachillerato.pdf" target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>CURSOS Y SEMINARIOS</div>
                                    <div className="container" style={{overflowY:'auto', overflowX:'hidden', height:'50%', width:'97%'}}>
                                        <table className="highlight">
                                            <tbody>
                                                <tr>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>Mayo 07 – 2020</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Certificación Básica Edmodo</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                        <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal3">
                                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                    <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./title/EDMODO.pdf" target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>Junio 16 – Noviembre 15 2020</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Cuso virtual Formación Docente.</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                        <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal4">
                                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                    <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./title/EDUCACIÒN MODELO FLEXIBLE.pdf" target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>Junio 09 – 2017</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Seminario internacional (Asistente).</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                        <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal5">
                                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                    <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-1" target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>Febrero – marzo 2009</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Congreso virtual (Asistente).</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                        <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal6">
                                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                    <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-1" target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>2009</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Seminario de psicología del aprendizaje (Asistente).</p></td>
                                                    <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                        <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal7">
                                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                    <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-1" target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal1" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Pregrado</h4>
                                    <p className="medium center">Fundación Universitaria Católica Lumen Gentium</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/3.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Licenciado en Informática Educativa</p>
                                    <p className="medium" style={{marginTop:'10px'}}>Tesis de grado:</p>
                                    <p className="light" style={{marginLeft:'10px', marginRight:'10px', wordBreak:'break-all'}}>
                                        Diseño de un ambiente virtual de aprendizaje como herramienta pedagógica y didáctica, de apoyo a los procesos de enseñanza – aprendizaje de la 
                                        contabilidad en los estudiantes de grado once del colegio Santa María de Pance. Dirigida por Mg. Roberto Ferro.
                                    </p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal2" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Secundaria</h4>
                                    <p className="medium center">Colegio Miguel Camacho Perea</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/4.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Bachiller Académico</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal3" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">edmodo</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/5.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Curso de Certificación Básica de Edmodo</p>
                                </div>
                                <div className="modal-footer">
                                    <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal4" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Escuela Integral</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/6.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>El sentido de ser Docente en un mundo cambiante</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal5" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Universidad Santiago de Cali (Cali – Colombia)</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/7.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Seminario internacional de construcción de paz imperfecta</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal6" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">MinEducación</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/8.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Primer Congreso Virtual Colombia Aprende</p>
                                    <p className="medium" style={{marginTop:'10px'}}>Proyectos colaborativos:</p>
                                    <p className="light" style={{marginLeft:'10px', marginRight:'10px'}}>
                                        Renovando las prácticas en el aula.
                                    </p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal7" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Fundación Universitaria Católica Lumen Gentium</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/3.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Seminario de psicología del aprendizaje</p>
                                    <p className="medium" style={{marginTop:'10px'}}>Tema:</p>
                                    <p className="light" style={{marginLeft:'10px', marginRight:'10px'}}>
                                        Como formar y garantizar un aprendizaje real en los educandos.
                                    </p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-1" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}>CURSOS Y SEMINARIOS</div>
                                    <div className="container" style={{overflowY:'auto', overflowX:'hidden', height:'87%', width:'97%'}}>
                                        <table className="highlight">
                                            <tbody>
                                              <tr>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>2006</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Capacitación en estudios estadísticos (Participante).</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal8">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                    <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-2" target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>2005</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Capacitación de trabajo social (Asistente).</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal9">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                    <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-2" target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>2004</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>I Congreso municipal de telecomunicaciones (Asistente).</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal10">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                    <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-2" target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>2004</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Capacitación en mantenimiento y ensamblaje de computadores.</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal11">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                    <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-2" target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>Agosto 2002 – Noviembre 2004</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Estudios de Ingeniería de Sistemas</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal12">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                    <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-2" target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'left'}}>Octubre 2000</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}><p style={{textAlign:'center'}}>Capacitación para animadores</p></td>
                                                <td style={{padding:'10px 0px 10px 0px', margin:0}}>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal13">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td style={{padding:'10px 0px 10px 10px', margin:0}}>
                                                    <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-2" target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal8" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Departamento Nacional de Estadística - DANE, Colciencias y DNP (Cali  – Colombia)</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/9.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Capacitación en estudios estadísticos para innovaciones tecnológicas.</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal9" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Departamento Nacional de Estadística - DANE, Colciencias y DNP (Cali  – Colombia)</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/9.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Capacitación de trabajo social en zonas de alto riesgo (rurales)</p>
                                    <p className="medium center" style={{marginTop:'10px'}}>Capacitación en cartografía (manejo de DMS y GPS).</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal10" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Universidad Libre. (Cali – Colombia)</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/10.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>I Congreso municipal de telecomunicaciones</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal11" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Instituto ITES. ml(Cali – Colombia)</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/11.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Capacitación en mantenimiento y ensamblaje de computadores.</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal12" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Universidad Libre. (Cali – Colombia)</p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/10.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Ingeniería de Sistemas y Telecomunicaciones. (V Semestre)</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="modal13" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Cursos y Seminarios</h4>
                                    <p className="medium center">Socorro Narváez C.A.S.D. (Cali – Colombia) </p>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                                        <img style={{maxWidth:"35%", height:'auto'}} src="./img/12.png" alt="logo"/>
                                    </div>
                                    <p className="medium center" style={{marginTop:'10px'}}>Capacitación para animadores y animadoras en la prevención de Farmacodependencia. (30 horas)</p>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-2" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}>EXPERIENCIA LABORAL</div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>UNIVERSIDAD ICESI</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Tutor Principal asignada al proyecto modalidad presencial, Diplomado Proyecto Pensamiento Computacional.</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">Julio – Septiembre 2021</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Timbiquí, Colombia</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Adriana Vélez Jones</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 314 864 16 88</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Realizar acompañamiento y asesoría como tutor principal a docentes del municipio de Timbiquí - Cauca en el diplomado de pensamiento computacional.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Realizar acompañamiento y asesoría como tutor principal a estudiantes de noveno a once del municipio de Timbiquí - Cauca en el diplomado de pensamiento computacional.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Realizar acompañamiento y asesoría como tutor principal a estudiantes de noveno a once del municipio de Timbiquí - Cauca en el diplomado de pensamiento computacional.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Realizar la respectiva documentación y almacenamiento de productos realizados por los participantes.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Organizar los informes requeridos por la universidad.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal14">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-3" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                                <div id="modal14" className="modal">
                                    <div className="modal-content">
                                        <h4 className="gbold">Más Información</h4>
                                        <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                    </div>
                                    <div className="modal-footer">
                                      <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                    </div>
                                </div>
                                <div id="doc-3" className="modal">
                                    <div className="modal-content">
                                        <h4 className="gbold">Documento o Certificado</h4>
                                        <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                    </div>
                                    <div className="modal-footer">
                                      <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                    </div>
                                </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>I3 NET</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Analista Telemedición</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">2020 - 2021</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">David Diaz</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 312 519 65 84</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Identificar claramente (posición GPS y registro fotográfico) el sitio señalado para la instalación del TGP.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Verificar que no haya obstáculos que obstruyan la luz solar que va a recibir el panel del TGP. 
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Identificar claramente (posición GPS y registro fotográfico) la ubicación posible de los TEPs en la ruta turística suministrada. 
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Verificar que no haya obstáculos que obstruyan la luz solar que va a recibir el panel del TEP.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Hacer la toma de fotografías de 360° correspondientes al entorno de la ubicación del TEP y la trayectoria solar. 
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Comprobar la comunicación entre TEP y TGP con ayuda de la aplicación Network Test Tool. En caso de problemas con la comunicación Bluetooth o con la ubicación del TEP, se debe comunicar con la coordinación del proyecto. 
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Hacer el procedimiento anterior (5 anteriores) con las otras 2 ubicaciones de los TEPs como corresponda (TEP -TGP ó TEP – TEP). 
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal15">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-4" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                                <div id="modal15" className="modal">
                                    <div className="modal-content">
                                        <h4 className="gbold">Más Información</h4>
                                        <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                    </div>
                                    <div className="modal-footer">
                                      <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                    </div>
                                </div>
                                <div id="doc-4" className="modal">
                                    <div className="modal-content">
                                        <h4 className="gbold">Documento o Certificado</h4>
                                        <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                    </div>
                                    <div className="modal-footer">
                                      <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                    </div>
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>UNIVERSIDAD EAFIT</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Consultor Analista III (Analista de proyectos adscritos)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">Octubre – Dic 2020</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Maribel Velasco</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 301 368 49 88</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Realizar acompañamiento remoto a las instituciones educativas distritales (IED) asignadas, realizando las actividades acordadas y necesarias para el desarrollo de las estrategias del proyecto
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Llevar a cabo las estrategias, funciones y actividades asignadas por el coordinador del proyecto y líderes de ejecución, de acuerdo con los lineamientos y directrices entregados por el equipo de la Universidad EAFIT.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Apoyar el diseño de una ruta metodológica para la identificación, documentación, visibilización y fortalecimiento pedagógico de experiencias destacadas.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Liderar y promover los encuentros remotos (talleres y reuniones) con funcionarios de las IED, de acuerdo a las temáticas y cronogramas definidos.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Apoyar el acompañamiento y fortalecimiento pedagógico de estas 20 experiencias destacadas.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal16">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./title/eafit-1.pdf" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                                <div id="modal16" className="modal">
                                    <div className="modal-content">
                                        <h4 className="gbold">Más Información</h4>
                                        <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                    </div>
                                    <div className="modal-footer">
                                      <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                    </div>
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 9 */}
                <div id="movPage9" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage8', 22, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>SECRETARIA DE EDUCACIÓN MUNICIPAL</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Facilitador Proyecto Mi Comunidad Es Escuela</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">Febrero 2019 – Diciembre 2019</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Adriana Vélez Jones</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 314 864 16 88</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Realizar acompañamiento para el uso y apropiación de las TIC en las IEO asignadas, apoyando a los docentes en el uso de administrador de contenidos como base para interacción web.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Desarrollar actividades de planeación estratégica con directivos docentes y docentes para la construcción de los Planes de Gestión TIC en las Instituciones educativas.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Apoyar el diseño de los lineamientos y ruta para la incorporación de las TIC a los procesos de gestión escolar para cada una de las Instituciones educativas.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Realizar acompañamiento para implementar de manera situada los lineamientos y ruta para la incorporación de las TIC a los procesos de gestión escolar para cada una de las Instituciones educativas.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Presentar los respectivos informes periódicos y finales de gestión, acordes a los formatos, fechas y solicitudes a la Secretaria de Educación.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal17">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-5" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                                <div id="modal17" className="modal">
                                    <div className="modal-content">
                                        <h4 className="gbold">Más Información</h4>
                                        <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                    </div>
                                    <div className="modal-footer">
                                      <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                    </div>
                                </div>
                                <div id="doc-5" className="modal">
                                    <div className="modal-content">
                                        <h4 className="gbold">Documento o Certificado</h4>
                                        <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                    </div>
                                    <div className="modal-footer">
                                      <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                    </div>
                                </div>
                            </div>
                            <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage9', 8, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 10 */}
                <div id="movPage10" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage9', 21, '', '', '', "4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>TALENTUM</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Coordinador Académico Suroccidente del Valle</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">Julio 2017 – Diciembre 2019</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Vanessa Rojas</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 890 2163</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Cualificar a coordinadores de Municipio en el modelo Educativo flexible escuela integral para educandos extra edad y adulto mayor de los 6 municipios del suroccidente del valle no certificados.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Capacitaciones emprendedoras para la creación de proyectos de exposición de feria del emprendimiento de la corporación talentum.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Capacitar a los formadores para apropiación del modelo Educativo flexible escuela integral de las instituciones del suroccidente del valle del cauca y así potencializar el proceso académico en jóvenes en extra edad y adulto mayor.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Apoyar instituciones educativas en procesos organizacionales desde la perspectiva administrativa, pedagógica y didáctica.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal18">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./title/CERTIFICACION TALENTUM 2019 - ANDRES FELIPE VALENCIA.pdf" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal18" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Más Información</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-6" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage10', 9, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 11 */}
                <div id="movPage11" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="6" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage10', 20, '', '', '', "5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>AVE FENIX</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Facilitador en la recolección de insumos para la creación de la política pública en SPA de la gobernación del valle.</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">Enero – Mayo 2018</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Vanessa Rojas</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 890 2163</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Coordinar con los diferentes municipios “Palmira, Candelaria, Guacarí, Cerrito, Pradera, Vijes, La cumbre, Restrepo, Jamundí” para la gestión y organización de los talleres.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Cualificar, apoyar y dirigir talleres en municipios del sur del valle para la búsqueda de insumos que ayuden a la creación de estrategias para crear una política pública en el valle frente a la prevención de sustancias psicoactivas.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Evaluar la información cartográfica de los municipios en la recolección de insumos.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Sistematizar las respuestas de los diferentes actores de los municipios como insumo para la creación de estrategias.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal19">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center " href="./title/CERTIFICACION TALENTUM 2018 - ANDRES FELIPE VALENCIA.pdf" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal19" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Más Información</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-7" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage11', 10, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 12 */}
                <div id="movPage12" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage11', 19, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>TALENTUM</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Coordinador Operativo Suroccidente del Valle</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">Julio 2016 – Diciembre 2016</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Vanessa Rojas</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 890 2163</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Atender y realizar seguimiento a los Coordinadores de zona asignados bajo la figura de supervisoras, brindando lineamientos para el desarrollo adecuado de las actividades para involucrar el modelo pedagógico flexible en los educandos extraedad y adultos de los 34 municipios no certificados del valle.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Capacitar a los formadores para apropiación del modelo pedagógico flexible de la institución y así potencializar el proceso académico.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Desarrollar horarios de manera que estén siempre adecuados a las necesidades de las instituciones y de la comunidad educativa.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal20">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center" href="./title/CERTIFICACION TALENTUM 2016 - ANDRES FELIPE VALENCIA.pdf" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal20" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Más Información</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-8" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="7" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage12', 11, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 13 */}
                <div id="movPage13" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="8" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage12', 18, '', '', '', "7")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>UNIVERSIDAD EAFIT</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Gestor Colegios 10 Tic</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">julio – Dic 2016</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Roberto Ferro</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 301 747 83 24</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div data-active="8" className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div data-active="8" className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Atender y realizar seguimiento a los formadores asignados bajo la figura de mentor, brindando lineamientos para el desarrollo adecuado de las Tic en el aula de clase.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Capacitar a los formadores para apropiación de la propuesta pedagógica haciendo uso activo de las Tic.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Desarrollar e Implementar el uso de las tic en el PEI de la Institución Educativa.
                                            </li>
                                        </ul>
                                    </div>
                                    <div data-active="8" className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal21">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-9" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal21" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Más Información</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-9" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage13', 12, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 14 */}
                <div id="movPage14" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage13', 17, '', '', '', "8")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>COLEGIO SANTA MARIA DE PANCE</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Coordinador Académico</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">septiembre 2014 – Junio 2016</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Jackeline Zúñiga Martínez</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 555 2080</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div data-active="9" className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div data-active="9" className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Atender y realizar seguimiento a los formadores asignados bajo la figura de mentor, brindando lineamientos para el desarrollo adecuado de las mallas curriculares, metodologías didácticas y pedagógicas de manera presencial.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Capacitar  a  los  formadores  para  apropiación  de  la  propuesta  pedagógica  de  la institución y así potencializar el proceso académico.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Mantener  y  desarrollar  el  currículo  y  los  horarios  de  manera  que  estén  siempre adecuados a las necesidades actuales del medio y de la comunidad educativa.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Implementar el proceso de articulación educativa con la universidad autónoma de occidente.
                                            </li>
                                        </ul>
                                    </div>
                                    <div data-active="9" className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal22">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-10" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal22" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Más Información</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-10" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="9" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage14', 13, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 15 */}
                <div id="movPage15" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="10" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage14', 16, '', '', '', "9")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>COLEGIO SANTA MARIA DE PANCE</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Director de Proyectos Administrativos</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">septiembre 2011 – Junio 2014</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Jackeline Zúñiga Martínez</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 555 2080</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div data-active="10" className="container" style={{overflowY:'auto', height:'10%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Orientación de las Asignaturas: Administración, Legislación, Contabilidad, Ciencias Políticas y Económicas y Estadística. Grados de Sexto a Once.
                                            </li>
                                        </ul>
                                    </div>
                                    <div data-active="10" className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>Principales Logros</div>
                                    <div data-active="10" className="container" style={{overflowY:'auto', height:'10%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Formación de aproximadamente 200 Educandos en el perfil empresarial y desarrollo de estructura de proyectos.
                                            </li>
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Creación y Desarrollo de la feria empresarial diseñando empresas, auto sostenible, amigable con el medio ambiente y con un sentido social.
                                            </li>
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Creación de proyectos tipo tesis para grado once; sustentado en la universidad Autónoma.
                                            </li>
                                        </ul>
                                    </div>
                                    <div data-active="10" className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal23">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-11" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal23" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Más Información</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-11" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage15', 14, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 16 */}
                <div id="movPage16" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage15', 15, '', '', '', "10")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>COLEGIO MILITAR TECNICO ALMIRANTE COLÓN</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Docente Matemáticas</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">septiembre 2006 – Septiembre 2011</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Andrés Alberto Arango</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 446 7674</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div data-active="11" className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div data-active="11" className="container" style={{overflowY:'auto', height:'30%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Orientación de las Asignaturas:  Aritmética, Geometría y Estadística en los grados Sexto, Séptimo y Once.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Orientación de grupos de primaria y bachiller.
                                            </li>
                                        </ul>
                                    </div>
                                    <div data-active="11" className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal24">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-12" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal24" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Más Información</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-12" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="11" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage16', 15, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 17 */}
                <div id="movPage17" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="12" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageBack('movPage16', 14, '', '', '', "11")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}></div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li><span className="gbold" style={{color:'rgb(240, 179, 35)'}}>Departamento Administrativo Nacional de Estadística – DANE</span></li>
                                                        <li className="sizetext" style={{textAlign:'justify', wordBreak:'break-word', lineHeight:'1.1'}}><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Cargo: </span><span className="light">Supervisor Rural de zonas de alto riesgo</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Fecha: </span><span className="light">enero 2003 – Agosto 2006</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Lugar: </span><span className="light">Cali, Valle del Cauca (Colombia)</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Jefe Inmediato: </span><span className="light">Harold Minota</span></li>
                                                        <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 661 8844</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div data-active="12" className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>RESPONSABILIDADES</div>
                                    <div data-active="12" className="container" style={{overflowY:'auto', height:'10%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Supervisión de recolección de la información poblacional y empresarial.
                                            </li>
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Capacitación y seguimiento de cartografía manual y digital en casco urbano y rural
                                            </li>
                                        </ul>
                                    </div>
                                    <div data-active="12" className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'5%'}}>Principales Logros</div>
                                    <div data-active="12" className="container" style={{overflowY:'auto', height:'10%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0, lineHeight:'1.1'}}>
                                                Alto rendimiento en la recolección de información con excelente posicionamiento cartográfico.
                                            </li>
                                        </ul>
                                    </div>
                                    <div data-active="12" className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center modal-trigger" href="#modal25">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                        <a style={{backgroundColor:'rgb(240, 179, 37)', opacity:'0.7', marginTop:'8px', fontSize:'x-large'}} className="btn-floating pulse waves-effect center modal-trigger" href="#doc-13" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div id="modal25" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Más Información</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div id="doc-13" className="modal">
                                <div className="modal-content">
                                    <h4 className="gbold">Documento o Certificado</h4>
                                    <h5><FontAwesomeIcon icon={faExclamationCircle} style={{color:'rgb(99,53,204)'}}/> No disponible</h5>
                                </div>
                                <div className="modal-footer">
                                  <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                </div>
                            </div>
                            <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage17', 16, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 18 */}
                <div id="movPage18" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageBack('movPage17', 13, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div data-active="13" className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}>EXPERIENCIA ACADÉMICA</div>
                                    <div data-active="13" className="container" style={{overflowY:'auto', height:'77%', width:'97%'}}>
                                        <ul>    
                                            <li className="sizetext light" style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0}}>
                                                Participación como ponente con el trabajo “I Feria Tecnológica y del Emprendimiento”, Colegio San Luis Gonzaga, Mayo 22 del 2014.
                                            </li>
                                            <li className="sizetext light " style={{textAlign:'justify', wordBreak:'break-word', padding:'0px 0px 10px', margin:0}}>
                                                Creador y desarrollador de los siguientes proyectos en el Colegio Santa María de Pance:
                                            </li>
                                            <ul className="collection" style={{opacity:'0.8', margin:0}}>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left', lineHeight:'1.1'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> “I Feria Empresarial y del Emprendimiento (The Green World)”, abril de 2012.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left', lineHeight:'1.1'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> “II Feria Empresarial y del Emprendimiento (Clus-Atom)”, abril de 2013.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left', lineHeight:'1.1'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> “III Feria Empresarial y del Emprendimiento (Dejando Huella)”, abril de 2014.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left', lineHeight:'1.1'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> “IV Feria Empresarial y del Emprendimiento (Soñar para Contar)”, abril de 2015.</p></li>
                                                <li className="light collection-item" style={{padding:'5px'}}><p style={{textAlign:'left', lineHeight:'1.1'}}><FontAwesomeIcon icon={faCaretRight} style={{color:'rgb(240, 179, 37)'}}/> “V Feria Empresarial y del Emprendimiento (Culturizate)”, abril de 2016.</p></li>                                                                                                
                                            </ul>                                            
                                        </ul>
                                    </div>
                                    <div data-active="13" className="container" style={{height:'10%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <a style={{backgroundColor:'white', opacity:'0.7', marginRight:'5px', marginTop:'8px'}} className="btn-floating pulse waves-effect center" href="https://andresfvalencia3000.wixsite.com/chiquiferia" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 19 */}
                <div id="movPage19" className="page">
                    <div className="front">
                        <div className="front__content background-page1">
                            <div data-active="14" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                <a style={{opacity:'0.9', backgroundColor:'#6335CC', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageBack('movPage18', 12, '', '', '', "13")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%',width:'100%'}}>
                                    <div className="gboldtw center" style={{paddingBottom:'5%', paddingTop:'8%'}}>REFERENCIAS PERSONALES</div>
                                    <div className="container" style={{overflowX:'auto', overflowY:'auto', height:'70%', width:'97%'}}>
                                        <table style={{fontSize:'x-small'}}>
                                            <thead>
                                              <tr>
                                                  <th className="bold" style={{minWidth:'100px'}}>Nombre</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Roberto Ferro</td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#referencia-1">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7', fontSize:"2.5em"}} className="btn-floating pulse waves-effect center" href="tel:+573017478324">
                                                        <FontAwesomeIcon icon={faPhoneSquareAlt} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7', fontSize:"2em"}} className="btn-floating pulse waves-effect center modal-trigger" href="#video-1">
                                                        <FontAwesomeIcon icon={faVideo} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Jessica Márquez</td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#referencia-2">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7', fontSize:"2.5em"}} className="btn-floating pulse waves-effect center" href="tel:+573017478324">
                                                        <FontAwesomeIcon icon={faPhoneSquareAlt} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7', fontSize:"2em"}} className="btn-floating pulse waves-effect center modal-trigger" href="#video-1">
                                                        <FontAwesomeIcon icon={faVideo} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sergio Ferro</td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7'}} className="btn-floating pulse waves-effect center modal-trigger" href="#referencia-3">
                                                        <FontAwesomeIcon icon={faPlus} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7', fontSize:"2.5em"}} className="btn-floating pulse waves-effect center" href="tel:+573017478324">
                                                        <FontAwesomeIcon icon={faPhoneSquareAlt} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a style={{backgroundColor:'white', opacity:'0.7', fontSize:"2em"}} className="btn-floating pulse waves-effect center modal-trigger" href="#video-1">
                                                        <FontAwesomeIcon icon={faVideo} style={{color:'rgb(99,53,204)'}}/>
                                                    </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="container" style={{height:'20%', width:'97%', display:'inline-flex', justifyContent:'flex-end'}}>
                                        <p className="light" style={{marginLeft:'10px', marginRight:'10px', wordBreak:'break-all'}}>
                                            Para todos los efectos legales certifico que todas las respuestas e informaciones anotadas por mí, en la presente hoja de vida son veraces (c. S.t. art. 62num).
                                        </p>
                                    </div>
                                    <div id="referencia-1" className="modal">
                                        <div className="modal-content">
                                            <h4 className="gbold">Roberto Ferro</h4>
                                            <ul>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Ocupación: </span><span className="light">Docente</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Institución: </span><span className="light">Universidad Católica Lumen Gentium</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 301 747 8324</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Correo: </span><span className="light">...</span></li>
                                            </ul>
                                        </div>
                                        <div className="modal-footer">
                                            <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                        </div>
                                    </div>
                                    <div id="referencia-2" className="modal">
                                        <div className="modal-content">
                                            <h4 className="gbold">Jessica Márquez</h4>
                                            <ul>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Ocupación: </span><span className="light">Ingeniero</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Institución: </span><span className="light">Senior Bancoomeva</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 305 235 1016</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Correo: </span><span className="light">...</span></li>
                                            </ul>
                                        </div>
                                        <div className="modal-footer">
                                            <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                        </div>
                                    </div>
                                    <div id="referencia-3" className="modal">
                                        <div className="modal-content">
                                            <h4 className="gbold">Sergio Ferro</h4>
                                            <ul>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Ocupación: </span><span className="light">Ingeniero</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Institución: </span><span className="light">Senior Adidas</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Teléfono: </span><span className="light">(57 2) 300 471 0565</span></li>
                                                <li className="sizetext"><span className="medium" style={{color:'rgb(240, 179, 35)'}}>Correo: </span><span className="light">...</span></li>
                                            </ul>
                                        </div>
                                        <div className="modal-footer">
                                            <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                        </div>
                                    </div>
                                    <div id="video-1" className="modal">
                                        <div className="modal-content">
                                            <h4 className="gbold">Video</h4>
                                            <h5 className="bold"><FontAwesomeIcon icon={faVideo} style={{color:'rgb(99,53,204)'}}/> Video no disponible</h5>
                                            
                                        </div>
                                        <div className="modal-footer">
                                            <a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
                                        </div>
                                    </div>
                                </div>
                                <div style={{height:'10%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                    <img style={{maxWidth:"35%", height:'auto'}} src={koulutus} alt="logo"/>
                                </div>
                            </div>
                            <div data-active="13" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/* <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage18', 17, '', '', '', "12")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 20 */}



            </div>      
        );
    }
}

export default DisplayMv;