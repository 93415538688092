import React, { Component } from "react";
import Contents from "./Contents";
import Lista from "./Lista";

class ControlList  extends Component{

    constructor(){
        super()
        this.state={
          listaEBooksVw:true,
          contentsVw:false,
        }
    }
    
    abrirEBooks(){
        this.setState({
            listaEBooksVw:false,
            contentsVw:true
        })
      }

    render(){
        return(
            <div className="App-cont">
                <div className="App-book App-mg">

                    {
                        this.state.listaEBooksVw?
                        <div className="Cursos">
                            {/* <h3>Hoja de Vida</h3> */}
                            <div className="Lista-cursos">
                                <span onClick={() => this.abrirEBooks()}><Lista courses="Hoja de vida 2021" imgurl="./img/cover_page/book1.png" imgalt="Septiembre 2021"/></span>
                                {/* <span ><Lista courses="Book 2" imgurl="./img/book2.png" imgalt="Book 2"/></span>
                                <span ><Lista courses="Book 3" imgurl="./img/book3.png" imgalt="Book 3"/></span>
                                <span ><Lista courses="Book 4" imgurl="./img/book4.png" imgalt="Book 4"/></span> */}
                            </div>
                        </div>
                        :null
                    }

                    {
                        this.state.contentsVw?
                        <Contents/>
                        :null
                    }

                </div>
            </div>           
        );
    }
}

export default ControlList;